
import { useSearchParams } from 'react-router-dom';
import { Registration } from './Registration'
import { RegistrationBeta } from './RegistrationBeta'



export function Regloader() {
    const [searchParams] = useSearchParams();
    const betaid = searchParams.get('betaid');
    if (betaid) {

        return <Registration lockemail={''} betaid={''} />
       
    } else {
        return <RegistrationBeta/>
    }
}