import { useNavigate, useParams } from "react-router-dom";
import { FlowTypes } from "./WalkthroughSteps";
import { useState } from "react";

function MenuItem(props: any) {
  const { url, title, img } = props
  const { onLinkClick, hoverTarget, setHoverTarget } = props.sharedProps

  return (
    <div className='col-12 col-sm-6 col-md-4 col-xl-3 py-3 cur-pointer' onClick={() => onLinkClick(`${url}`)}>
      <div onMouseEnter={e => { setHoverTarget(title) }} onMouseLeave={e => { setHoverTarget('none') }}>
        <img style={{ maxHeight: '200px' }} src={`/media/menu-products/${img}${hoverTarget === title ? '.gif' : '.png'}`} alt="" />
      </div>
      <div>{title}</div>
    </div>
  )
}

function Firstbc() {
  const navigate = useNavigate();
  const { flowType } = useParams() // The current value at the end of the compiler URL
  const [hoverTarget, setHoverTarget] = useState('none')
  const getFlowName = () => FlowTypes[flowType || 'full']
  const onLinkClick = (type) => navigate(`/vcard/compiler/${getFlowName()}/${type}`)
  const sharedProps = { onLinkClick: onLinkClick, hoverTarget: hoverTarget, setHoverTarget: setHoverTarget }

  return (
    <>
      <div className='w-100 h-100 d-flex flex-column align-items-center justify-content-center'>
        { flowType === 'repeat'
        ? <img style={{ maxHeight: '120px', width: '100%' }} alt="" src='/media/menu-products/_MenuSelectAdditional.svg'/>
        : <img style={{ maxHeight: '120px', width: '100%' }} alt="" src='/media/menu-products/_MenuTitleHeader.svg' />
        }
        <div className='w-100 d-flex flex-column flex-sm-row align-items-center justify-content-center gap-4'>
          <div className='row' style={{ margin: '15px', fontSize: '15px', textAlign: 'center', paddingLeft: '10vw', paddingRight: '10vw', }}>
            <MenuItem sharedProps={sharedProps} url='bc' title='Business Card (2" x 3.5")' img='Business Card' />
            <MenuItem sharedProps={sharedProps} url='pc' title='Postcard (4" x 6")' img='Postcard'/>
            <MenuItem sharedProps={sharedProps} url='pc2' title='Mailer (5.5" x 8")' img='Postcard'/>
            <MenuItem sharedProps={sharedProps} url='pc3' title='Mailer (5.5" x 11")' img='Postcard'/>
            <MenuItem sharedProps={sharedProps} url='qr' title='Sticker' img='Sticker'/>
            <MenuItem sharedProps={sharedProps} url='custom' title='Coaster' img='Coaster'/>
            <MenuItem sharedProps={sharedProps} url='custom' title='Hat' img='Hat'/>
            <MenuItem sharedProps={sharedProps} url='custom' title='Mug' img='Mug'/>
            <MenuItem sharedProps={sharedProps} url='custom' title='T-shirt' img='T-shirt'/>
            <MenuItem sharedProps={sharedProps} url='custom' title='Tumbler' img='Tumbler'/>
          </div>
        </div>
        {flowType === 'repeat' &&
          <div className='py-4 text-center'>
            <h2 style={{ fontSize: '32px' }} className='fw-bolder font-black fs-1 d-flex align-items-center text-dark'>
              <button onClick={() => navigate(`/dashboard`)} className='btn bg-black text-white rounded-pill gap-3'>
                Back To Dashboard
              </button>
            </h2>
          </div>
        }
      </div>
    </>
  )
}

export default Firstbc