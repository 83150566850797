// All Steps Possible In Scene/Marker Creation

export enum Step {
  UploadMarker_1, TransformQR_2, MarkerSceneLink_3,
  UploadVideo_4, UploadLogo_5, AddLinks_6,
  Dashboard, Publish, PublishMarker,
  Unknown, //placeholder for unknown states left behind from deletion of activeTab
}

// Flow IDs & Dictionary (IDs must exist in dictionary)

export const FlowTypes = {
  full: 'full',
  repeat: 'full', //acts as an alias for 'full' flow when user wants to build another card
  markeronly: 'markeronly'
}

const FlowConfigs = {
  full: [
    Step.Dashboard,
    Step.UploadMarker_1,
    Step.UploadVideo_4,
    Step.UploadLogo_5,
    Step.AddLinks_6,
    Step.Publish,
  ],
  markeronly: [
    Step.Dashboard,
    Step.UploadMarker_1,
    Step.TransformQR_2,
    Step.MarkerSceneLink_3,
    Step.PublishMarker,
  ],
}

// Get Next or Previous State

export const GetFirstStep = (flowType) => FlowConfigs[flowType][1] //the 0th index is reserved for 'Dashboard' step
export const GetNext      = (currStep, flowType) => getNext(currStep, FlowConfigs[flowType])
export const GetPrevious  = (currStep, flowType) => getPrev(currStep, FlowConfigs[flowType])

const getNext = (currStep, flow) => flow[flow.indexOf(currStep) + 1]
const getPrev = (currStep, flow) => flow[flow.indexOf(currStep) - 1]

// Helpers

export const IsPublishStep = (currStep) => {
  return currStep === Step.Publish || currStep === Step.PublishMarker;
}

export const IsMarkerStep = (currStep) => {
  return currStep === Step.UploadMarker_1
      || currStep === Step.TransformQR_2
      || currStep === Step.MarkerSceneLink_3
      || currStep === Step.PublishMarker;
}

export const IsSceneStep = (currStep) => {
  return currStep === Step.UploadVideo_4
      || currStep === Step.UploadLogo_5
      || currStep === Step.AddLinks_6
      || currStep === Step.Publish;
}