/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
// import { getUserByToken, register } from '../core/_requests'
import { Link, useNavigate } from 'react-router-dom'
// import { toAbsoluteUrl } from '../../../../_4threal/helpers'
import { PasswordMeterComponent } from '../../../../_4threal/assets/ts/components'
import { useAuth } from '../core/Auth'
import { authSignup } from '../../../../redux/action/auth'
import { useDispatch } from 'react-redux'
// import { generatePopup } from '../../../../utils/popup'
import { authLogin } from '../../../../redux/action/auth'
import { Button } from 'react-bootstrap'
import { getCards } from '../../../../redux/action/card'
import {auth} from '../../../../firebase';
import { GoogleAuthProvider, signInWithPopup} from 'firebase/auth'

let initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  email: Yup.string().email('Wrong email format').required('Email is required'),
  lastname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
  acceptTerms: Yup.boolean().required('You must accept the terms and conditions'),
})



export function Registration(props: any = { lockemail: '', betaId:'' }) {
  const {lockemail, betaid} = props

  const [loading, setLoading] = useState(false)
  const [passwordVisible, setPasswordVisible] = useState(false)
  // const { saveAuth, setCurrentUser } = useAuth() //omitted due to eslint warning
  const { saveAuth } = useAuth()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const viewicon = {
    top: '50% ',
    transform: 'translateY(-50%)',
    right: '10px',
  }

  const bgTransparent = { background: 'transparent' }

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        /*
                const userCredential = await createUserWithEmailAndPassword(auth, values.email, values.password);
                const user = userCredential.user;

                // Set displayName for the user
                  await updateProfile(user, {
                    displayName: `${values.firstname} ${values.lastname}`,
                    // photoURL can also be set here if needed
                  });
                  setLoading(false)
                  // User signed up successfully, redirect or clear form etc.
                  */
        let email = values.email
        if (lockemail) {
          email = lockemail
        }
        dispatch(
          authSignup({
            firstName: values.firstname,
            lastName: values.lastname,
            email: email,
            password: values.password,
            Terms: values.acceptTerms,
          })
        )
          .then((res: any) => {

            const uid = localStorage.getItem('userId')
            dispatch(getCards(50, 1, uid)).then((resp) => {
              navigate("/dashboard")
/*
              if (resp?.length > 0) {

              } else {
                navigate("/firstbc/full")
              }
              */
            })
          })
          .catch((error) => {
            setStatus((error?.response?.data?.message).replace(/"/g, ''))
          })
        setLoading(false)
      } catch (error) {
        saveAuth(undefined)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const handleGoogleSignIn = async () => {
    setLoading(true)
    const provider = new GoogleAuthProvider()
    try {
      const result = await signInWithPopup(auth, provider)
      const user = result.user
      dispatch(authLogin({ email: user.email, uid: user.uid }))
        .then(() => {
          navigate('/dashboard')
        })
      localStorage.setItem('userId', user.uid)
    } catch (error) {
      console.error('Error during Google sign-in', error)
    }
    setLoading(false)
  }

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework p-5'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-start mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Get Started</h1>
        <p className='font-dark1'>Welcome to Xtynded Reality&#8482; — Let’s create your account.</p>
      </div>
      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      <div className='row'>
        <div className='col-12 col-md-6 fv-row mb-8'>
          <label className='form-label fw-semibold font-dark1 fs-6'>First name</label>
          <input
            placeholder='First name'
            type='text'
            style={bgTransparent}
            autoComplete='off'
            {...formik.getFieldProps('firstname')}
            className={clsx(
              'form-control bg-transparent',
              {
                'border-red': formik.touched.firstname && formik.errors.firstname,
              },
              {
                'border-primary': formik.touched.firstname && !formik.errors.firstname,
              }
            )}
            onChange={(e) => {
              const inputValue = e.target.value
              if (!inputValue.match(/^[A-Za-z]+$/)) {
                e.target.value = inputValue.replace(/[^A-Za-z]/g, '')
              }
              formik.handleChange(e)
            }}
          />
          {formik.touched.firstname && formik.errors.firstname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.firstname}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-12 col-md-6 fv-row mb-8'>
          <label className='form-label fw-semibold font-dark1 fs-6'>Last name</label>
          <input
            placeholder='Last name'
            type='text'
            style={bgTransparent}
            autoComplete='off'
            {...formik.getFieldProps('lastname')}
            className={clsx(
              'form-control bg-transparent',
              {
                'border-red': formik.touched.lastname && formik.errors.lastname,
              },
              {
                'border-primary': formik.touched.lastname && !formik.errors.lastname,
              }
            )}
            onChange={(e) => {
              const inputValue = e.target.value
              if (!inputValue.match(/^[A-Za-z]+$/)) {
                e.target.value = inputValue.replace(/[^A-Za-z]/g, '')
              }
              formik.handleChange(e)
            }}
          />
          {formik.touched.lastname && formik.errors.lastname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.lastname}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fw-semibold font-dark1 fs-6'>Email address </label>
        <input
          placeholder='Email'
          style={bgTransparent}
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'border-red': formik.touched.email && formik.errors.email },
            {
              'border-primary': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-semibold font-dark1 fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              style={bgTransparent}
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'border-red font-red': formik.touched.password && formik.errors.password,
                },
                {
                  'border-primary': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            <div
              style={{ top: '50%', right: '10px', width: '50px', transform: 'translateY(-50%)' }}
              className='d-flex align-items-center mb-3 position-absolute '
              data-kt-password-meter-control='highlight'
            >
              <div style={{ flexGrow: '0.2' }} className='bg-secondary  rounded h-20px me-2'></div>
              <div style={{ flexGrow: '0.2' }} className='bg-secondary  rounded h-20px me-2'></div>
              <div style={{ flexGrow: '0.2' }} className='bg-secondary  rounded h-20px me-2'></div>
              <div style={{ flexGrow: '0.2' }} className='bg-secondary  rounded h-20px '></div>
            </div>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>

        <div className='text-muted font-gray1'>
          Use 8 or more characters with a mix of letters, numbers & symbols
        </div>
      </div>
      <div className='fv-row mb-5'>
        <label className='form-label fw-semibold text-dark fs-6'>Confirm Password</label>
        <div className='position-relative'>
          <input
            type={passwordVisible ? 'text' : 'password'}
            style={bgTransparent}
            placeholder='Password confirmation'
            autoComplete='off'
            {...formik.getFieldProps('changepassword')}
            className={clsx(
              'form-control bg-transparent',
              {
                'border-red font-red':
                  formik.touched.changepassword && formik.errors.changepassword,
              },
              {
                'border-primary': formik.touched.changepassword && !formik.errors.changepassword,
              }
            )}
          />

          {passwordVisible ? (
            <i
              style={viewicon}
              onClick={() => setPasswordVisible(!passwordVisible)}
              className='bi bi-eye-slash fs-5 position-absolute'
            ></i>
          ) : (
            <i
              style={viewicon}
              onClick={() => setPasswordVisible(!passwordVisible)}
              className='bi bi-eye fs-5 position-absolute'
            ></i>
          )}
        </div>
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input '
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />

          <span className='font-dark1'>Remember this account</span>
        </label>
      </div>
      {/* <div className='fv-row mb-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <span>
            I Accept the{' '}
            <a
              href='#'
              target='_blank'
              className='ms-1 link-primary'
            >
              Terms
            </a>
            .
          </span>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </label>
      </div> */}
      <div className='row align-items-center'>
        <div className='col-12 col-md-6'>
          <Button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 '
            disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
          >
            {!loading && <span className='indicator-label'>Create an account</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Create an account
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </Button>
        </div>
        <div className='col-12 col-md-6  mt-5'>
          <div className='font-dark1 text-center  fs-6 '>
            Have an account?{' '}
            <Link to='/auth/login' className='text-decoration-underline font-dark1  fw-semibold'>
              Login
            </Link>
          </div>
        </div>
      </div>
    </form>
  )
}
