/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { getActiveCards } from '../../../redux/action/card';
import timeSince from '../../../utils/timesince';

function Admintools() {
  const navigate = useNavigate();
  // const token = localStorage.getItem('userId') //omitting due to eslint warning

  return (
    <div style={{margin:'20px'}}>
     <h1 >Administration Tools</h1>
     <div style={{border:'1px, solid, black', paddingTop:'10px', paddingLeft:'10px', marginBottom:'15px'}}>

      <h3 style={{marginBottom:'10px'}}>Invite Beta Users</h3>
      <span>Enter email addresses separated by commas</span>
      <div style={{margin:'15px'}}>
        <textarea style={{width:'100%'}} rows={5} ></textarea >
        <button style={{marginTop:'10px', padding:'10px', backgroundColor:'blue', color:'white', border:'none'}}>Send Invites</button>
      </div>
     </div>
     <div style={{border:'1px, solid, black', paddingTop:'10px', paddingLeft:'10px',  marginBottom:'15px'}}>

      <h3 style={{marginBottom:'10px'}}>Invite Users With Offer Code</h3>
      <span>Select Offer Code</span>
      <select name="offertype" id="offertype">
                <option value="trial">Trial</option>
                <option value="discount">Discount</option>
            </select><br></br>
      <span>Enter email addresses separated by commas</span>
      <div style={{margin:'15px'}}>
        <textarea style={{width:'100%'}} rows={5} ></textarea >
        <button style={{marginTop:'10px', padding:'10px', backgroundColor:'blue', color:'white', border:'none'}}>Send Invites</button>
      </div>
      </div>
      <div style={{border:'1px, solid, black', paddingTop:'10px', paddingLeft:'10px',  marginBottom:'15px'}}>

      <h3 style={{marginBottom:'10px'}}>Create Offer Codes</h3>
      <span>Enter email addresses separated by commas</span>
      <div style={{margin:'15px'}}>
        <div className="row">
          <div className="col-3">
            offer type:
            <select name="offertype" id="offertype">
                <option value="trial">Trial</option>
                <option value="discount">Discount</option>
                <option value="trial+discount">Trail & Discount</option>
            </select>

          </div>
          <div className="col-3">
             offer code:<input type="text" placeholder="Enter Value" style={{width:'40%', marginTop:'10px'}} />
          </div>
          <div className="col-3">
             offer value:<input type="number" placeholder="Enter Value" style={{width:'40%', marginTop:'10px'}} />
          </div>
          <div className="col-3">
             offer expiration :<input type="date" id="start" name="trip-start" value="2024-11-01" min="2024-10-05" max="2025-12-31" />
          </div>
          <div className="col-3">
              offer limit:<input type="number" placeholder="Enter Value" style={{width:'40%', marginTop:'10px'}} />
          </div>
      </div>
        <button style={{marginTop:'10px', padding:'10px', backgroundColor:'blue', color:'white', border:'none'}}>Create Offer Code</button>
      </div>
      </div>
    </div>
  )
}

export default Admintools
