// import { API_URL } from "../../config";
// import { generatePopup } from '../../utils/popup'
import short from 'short-uuid'
import { AppLog } from '../../_4threal/helpers';
import { firestore, storage } from '../../firebase'
import { ref, uploadBytesResumable, getDownloadURL, listAll } from 'firebase/storage'
import {
  Timestamp,
  doc, getDoc, getDocs, setDoc, updateDoc,
  collection, collectionGroup, query, where,
  // addDoc, endAt, orderBy, startAt, serverTimestamp,documentId
} from 'firebase/firestore'
import { Action } from '../actionTypes';

export const uploadBlob: any = (payload: any) => async (dispatch: any) => {
  //FYI this function does not dispatch a new state
  return new Promise(async (resolve, reject) => {
    const uid = localStorage.getItem('userId')
    dispatch({
      type: Action.CREATE_BLOB_INIT,
    })
    try {
      let url = payload.dirs.join('/')
      if (payload.setUser) {
        url = `usercontent/${uid}/${url}`
      }
      const storageRef = ref(storage, `${url}/${payload.filename}`)
      const uploadTask = uploadBytesResumable(storageRef, payload.blob)
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          dispatch({
            type: Action.CREATE_BLOB_SUCCESS,
            payload: {
              progress: progress,
              downloadURL: '',
              error: '',
            },
          })
        },
        (error) => {
          AppLog(error)
          dispatch({
            type: Action.CREATE_BLOB_FAIL,
            payload: {
              progress: 0,
              downloadURL: '',
              error: 'Failed to upload blob!',
            },
          })
        },
        async () => {
          const downloadURL = await getDownloadURL(storageRef)
          dispatch({
            type: Action.CREATE_BLOB_SUCCESS,
            payload: {
              progress: 100,
              downloadURL: downloadURL,
              error: '',
            },
          })
          resolve(downloadURL)
          // generatePopup('success', 'Blob was created successfully!')
        }
      )
    } catch (error: any) {
      dispatch({
        type: Action.CREATE_BLOB_FAIL,
        payload: 'Failed to upload blob!',
      })
    }
  })
}

export const getBlobUrl: any = (payload: any) => async (dispatch: any) => {
  //FYI this function does not dispatch a new state
  return new Promise(async (resolve, reject) => {
    const uid = localStorage.getItem('userId')
    dispatch({
      type: Action.GET_BLOB_URL_INIT,
    })
    try {
      let url = payload.dirs.join('/')
      if (payload.setUser) {
        url = `usercontent/${uid}/${url}`
      }
      const storageRef = ref(storage, `${url}/${payload.filename}`)
      const downloadURL = await getDownloadURL(storageRef)
      dispatch({
        type: Action.GET_BLOB_URL_SUCCESS,
        payload: downloadURL,
      })
      resolve(downloadURL)
      //generatePopup("success", "Blob was created successfully!");
    } catch (error: any) {
      console.log('FAILED TO GET URL');
      dispatch({
        type: Action.GET_BLOB_URL_FAIL,
        payload: 'Failed to get url!',
      })
      resolve(Action.GET_BLOB_URL_FAIL) //TODO: need to refactor the receiver code to listen for actionTypes.CREATE_BLOB_FAIL
    }
  })
}

export const getAssetUrlList: any = (payload: any) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    const uid = localStorage.getItem('userId')
    dispatch({
      type: Action.GET_ASSET_URL_LIST_INIT,
    })
    try {
      let url = payload.dirs.join('/')
      if (payload.setUser) {
        url = `usercontent/${uid}/${url}`
      }
      const storageRef = ref(storage, `${url}`)
      listAll(storageRef)
        .then((res) => {
          res.items.forEach((itemRef) => {
            // All the items under listRef.
            // console.log('itemRef', itemRef.name);
          });
          dispatch({
            type: Action.GET_ASSET_URL_LIST_SUCCESS,
            payload: res,
          })
          resolve(res.items);
        })
        .catch((error) => {
          console.log('FAILED TO GET URL');
          console.log(error);
          dispatch({
            type: Action.GET_ASSET_URL_LIST_FAIL,
            payload: 'Failed to get url!',
          })
          resolve(Action.GET_ASSET_URL_LIST_FAIL)
        });
      //generatePopup("success", "Blob was created successfully!");
    } catch (error: any) {
      console.log('FAILED TO GET URL');
      dispatch({
        type: Action.GET_ASSET_URL_LIST_FAIL,
        payload: 'Failed to get url!',
      })
      resolve(Action.GET_ASSET_URL_LIST_FAIL)
    }
  })
}

export const get3DScene: any = (payload: any) => async (dispatch: any) => {
  //FYI this function does not dispatch a new state
  const jsonUrl = await dispatch(
    getBlobUrl({
      filename: `${payload.sceneFileName}`,
      dirs: ['assets'],
      setUser: true,
    })
  );

  try {
    let response = await fetch(jsonUrl);
    const jsonFromFirebase = await response.json();
    return { responseType: 'success', projectJSON: jsonFromFirebase };
  } catch (error) {
    return { responseType: 'error', error: error };
  }
}

export const updateAsset: any = (payload: any) => async (dispatch: any) => {
  //TODO: This is duplicate code from 'updateCard', consider consolidation
  return new Promise(async (resolve, reject) => {
    const uid = localStorage.getItem('userId')
    dispatch({ type: Action.POST_UPDATE_ASSET_INIT })
    try {
      if (!('_id' in payload)) { throw new Error('Asset id is not defined!') }
      const id = payload._id
      payload['updated_at'] = new Date().getTime()

      const cardRef = doc(firestore, `users/${uid}/assets/${id}`)
      await updateDoc(cardRef, payload)
      dispatch({
        type: Action.POST_UPDATE_ASSET_SUCCESS,
        payload: payload,
      })
      resolve(payload)
    } catch (error: any) {
      AppLog(error)
      dispatch({
        type: Action.POST_UPDATE_ASSET_FAIL,
        payload: 'Failed to create asset!',
      })
    }
  })
}

export const updateCard: any = (payload: any) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    const uid = localStorage.getItem('userId')
    dispatch({
      type: Action.POST_UPDATE_CARD_INIT,
    })
    try {
      if (!('_id' in payload)) {
        throw new Error('Card id is not defined!')
      }
      const id = payload._id
      //delete payload._id
      payload['updated_at'] = new Date().getTime()

      const cardRef = doc(firestore, `users/${uid}/scenes/${id}`)
      // let res = await updateDoc(cardRef, payload) //omitting "res" due to eslint warning
      await updateDoc(cardRef, payload)
      dispatch({
        type: Action.POST_UPDATE_CARD_SUCCESS,
        payload: payload,
      })
      //generatePopup('success', 'Card was created successfully!')
      resolve(payload)
    } catch (error: any) {
      AppLog(error)
      dispatch({
        type: Action.POST_UPDATE_CARD_FAIL,
        payload: 'Failed to create card!',
      })
    }
  })
}

export const updateMarker: any = (payload: any) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    const uid = localStorage.getItem('userId')
    dispatch({
      type: Action.POST_UPDATE_MARKER_INIT,
    })
    try {
      if (!('_id' in payload)) {
        throw new Error('Marker id is not defined!')
      }
      const id = payload._id
      //delete payload._id
      payload['updated_at'] = new Date().getTime()

      const cardRef = doc(firestore, `users/${uid}/markers/${id}`)
      // let res = await updateDoc(cardRef, payload) //omitting "res" due to eslint warning
      await updateDoc(cardRef, payload)
      dispatch({
        type: Action.POST_UPDATE_MARKER_SUCCESS,
        payload: payload,
      })
      //generatePopup('success', 'Card was created successfully!')
      resolve(payload)
    } catch (error: any) {
      AppLog(error)
      dispatch({
        type: Action.POST_UPDATE_MARKER_FAIL,
        payload: 'Failed to create markre!',
      })
    }
  })
}

export const createAsset: any = (payload: any) => async (dispatch: any) => {
  //TODO: This is duplicate code from 'createCard', consider consolidation
  return new Promise(async (resolve, reject) => {
    const uid = localStorage.getItem('userId')
    dispatch({
      type: Action.POST_CREATE_ASSET_INIT,
    })
    try {
      let id = short.generate()
      if ('_id' in payload && payload._id) {id = payload._id}

      const cardRef = doc(firestore, `users/${uid}/assets/${id}`)
      payload['uid'] = uid
      payload['_id'] = id
      payload['updatedAt'] = new Date().getTime()
      payload['createdAt'] = new Date().getTime()
      await setDoc(cardRef, payload)
      dispatch({
        type: Action.POST_CREATE_ASSET_SUCCESS,
        payload: payload,
      })
      resolve(payload)
    } catch (error: any) {
      AppLog(error)
      dispatch({
        type: Action.POST_CREATE_ASSET_FAIL,
        payload: 'Failed to create card!',
      })
    }
  })
}

export const createCard: any = (payload: any) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    const uid = localStorage.getItem('userId')
    dispatch({
      type: Action.POST_CREATE_CARD_INIT,
    })
    try {
      let id = short.generate()

      if ('_id' in payload && payload._id) {
        id = payload._id
      }

      const cardRef = doc(firestore, `users/${uid}/scenes/${id}`)
      payload['uid'] = uid
      payload['_id'] = id
      payload['updatedAt'] = new Date().getTime()
      payload['createdAt'] = new Date().getTime()
      //AppLog('payload', payload)
      await setDoc(cardRef, payload)
      dispatch({
        type: Action.POST_CREATE_CARD_SUCCESS,
        payload: payload,
      })
      //generatePopup('success', 'Card was created successfully!')
      resolve(payload)
    } catch (error: any) {
      AppLog(error)
      dispatch({
        type: Action.POST_CREATE_CARD_FAIL,
        payload: 'Failed to create card!',
      })
    }
  })
}

export const createMarker: any = (payload: any) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    const uid = localStorage.getItem('userId')
    dispatch({
      type: Action.POST_CREATE_MARKER_INIT,
    })
    try {
      let id = short.generate()

      if ('_id' in payload && payload._id) {
        id = payload._id
      }

      const cardRef = doc(firestore, `users/${uid}/markers/${id}`)
      payload['uid'] = uid
      payload['_id'] = id
      payload['updatedAt'] = new Date().getTime()
      payload['createdAt'] = new Date().getTime()
      //AppLog('payload', payload)
      await setDoc(cardRef, payload)
      dispatch({
        type: Action.POST_CREATE_MARKER_SUCCESS,
        payload: payload,
      })
      //generatePopup('success', 'Card was created successfully!')
      resolve(payload)
    } catch (error: any) {
      AppLog(error)
      dispatch({
        type: Action.POST_CREATE_MARKER_FAIL,
        payload: 'Failed to create marker!',
      })
    }
  })
}

export const getAssets: any = () => (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    const uid = localStorage.getItem('userId')
    dispatch({ type: Action.GET_ALL_ASSETS_INIT })
    try {
      const querySnapshot = await getDocs(collection(firestore, `users/${uid}/assets`))
      const res = querySnapshot.docs.map(doc => doc.data())
      dispatch({
        type: Action.GET_ALL_ASSETS_SUCCESS,
        payload: res,
      })
      resolve(res)
    } catch (error: any) {
      AppLog(error)
      dispatch({
        type: Action.GET_ALL_ASSETS_FAIL,
        payload: 'Failed to get assets!',
      })
    }
  })
}

export const getCards: any = (payload: any) => (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    const uid = localStorage.getItem('userId')
    dispatch({
      type: Action.GET_ALL_CARD_INIT,
    })
    try {
      // let plimit = payload?.itemsPerPage ? payload?.itemsPerPage : 100
      // let page = payload?.currentPage ? payload?.currentPage : 1
      // let offset = (page - 1) * plimit
      // AppLog(plimit)
      // AppLog(page)
      // AppLog(offset)
      // AppLog(offset + plimit)

      const cardsRef = collection(firestore, `users/${uid}/scenes`)
      const q = query(cardsRef, where('isDeleted', '==', false))
      const querySnapshot = await getDocs(q)

      const res = querySnapshot.docs.map((doc) => {
        return doc.data()
      })
      dispatch({
        type: Action.GET_ALL_CARD_SUCCESS,
        payload: res,
      })
      resolve(res)
    } catch (error: any) {
      AppLog(error)
      dispatch({
        type: Action.GET_ALL_CARD_FAIL,
        payload: 'Failed to get scenes!',
      })
    }
  })
}

export const getMarkers: any = (payload: any) => (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    const uid = localStorage.getItem('userId')
    dispatch({
      type: Action.GET_ALL_MARKER_INIT,
    })
    try {
      // let plimit = payload?.itemsPerPage ? payload?.itemsPerPage : 100
      // let page = payload?.currentPage ? payload?.currentPage : 1
      // let offset = (page - 1) * plimit
      // AppLog(plimit)
      // AppLog(page)
      // AppLog(offset)
      // AppLog(offset + plimit)

      const markersRef = collection(firestore, `users/${uid}/markers`)
      const q = query(markersRef, where('isDeleted', '==', false))
      const querySnapshot = await getDocs(q)

      const res = querySnapshot.docs.map((doc) => {
        return doc.data()
      })
      dispatch({
        type: Action.GET_ALL_MARKER_SUCCESS,
        payload: res,
      })
      resolve(res)
    } catch (error: any) {
      AppLog(error)
      dispatch({
        type: Action.GET_ALL_MARKER_FAIL,
        payload: 'Failed to get markers!',
      })
    }
  })
}

export const getOrders: any = (payload: any) => (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    const uid = localStorage.getItem('userId')
    dispatch({
      type: Action.GET_ALL_ORDERS_INIT,
    })
    try {
      // let plimit = payload?.itemsPerPage ? payload?.itemsPerPage : 100 //omitting due to eslint warning
      // let page = payload?.currentPage ? payload?.currentPage : 1 //omitting due to eslint warning
      // let offset = (page - 1) * plimit //omitting due to eslint warning

      //AppLog(plimit)
      //AppLog(page)
      //AppLog(offset)
      //AppLog(offset + plimit)
      const ordersRef = collection(firestore, `users/${uid}/orders`)
      const q = query(ordersRef, where('isPaid', '==', true))
      const querySnapshot = await getDocs(q)

      const res = querySnapshot.docs.map((doc) => {
        return doc.data()
      })
      dispatch({
        type: Action.GET_ALL_ORDERS_SUCCESS,
        payload: res,
      })
      resolve(res)
    } catch (error: any) {
      AppLog(error)
      dispatch({
        type: Action.GET_ALL_ORDERS_FAIL,
        payload: 'Failed to get orders!',
      })
    }
  })
}

export const getDeletedCards: any = (payload: any) => (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    const uid = localStorage.getItem('userId')
    dispatch({
      type: Action.GET_ALL_DELETE_CARD_INIT,
    })
    try {
      // let plimit = payload?.itemsPerPage ? payload?.itemsPerPage : 100
      // let page = payload?.currentPage ? payload?.currentPage : 1
      // let offset = (page - 1) * plimit
      // AppLog(plimit)
      // AppLog(page)
      // AppLog(offset)
      // AppLog(offset + plimit)

      const cardsRef = collection(firestore, `users/${uid}/scenes`)
      const q = query(cardsRef, where('isDeleted', '==', true))
      const querySnapshot = await getDocs(q)

      const res = querySnapshot.docs.map((doc) => {
        return doc.data()
      })
      dispatch({
        type: Action.GET_ALL_DELETE_CARD_SUCCESS,
        payload: res,
      })
      resolve(res)
    } catch (error: any) {
      AppLog(error)
      dispatch({
        type: Action.GET_ALL_DELETE_CARD_FAIL,
        payload: 'Failed to get scenes!',
      })
    }
  })
}

export const getDeletedMarkers: any = (payload: any) => (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    const uid = localStorage.getItem('userId')
    dispatch({
      type: Action.GET_ALL_DELETE_MARKER_INIT,
    })
    try {
      // let plimit = payload?.itemsPerPage ? payload?.itemsPerPage : 100
      // let page = payload?.currentPage ? payload?.currentPage : 1
      // let offset = (page - 1) * plimit
      // AppLog(plimit)
      // AppLog(page)
      // AppLog(offset)
      // AppLog(offset + plimit)

      const cardsRef = collection(firestore, `users/${uid}/markers`)
      const q = query(cardsRef, where('isDeleted', '==', true))
      const querySnapshot = await getDocs(q)

      const res = querySnapshot.docs.map((doc) => {
        return doc.data()
      })
      dispatch({
        type: Action.GET_ALL_DELETE_MARKER_SUCCESS,
        payload: res,
      })
      resolve(res)
    } catch (error: any) {
      AppLog(error)
      dispatch({
        type: Action.GET_ALL_DELETE_MARKER_FAIL,
        payload: 'Failed to get markers!',
      })
    }
  })
}

export const getCard: any = (payload: any) => (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    // const token: any = localStorage.getItem("userId") //omitting due to eslint warning
    dispatch({
      type: Action.GET_ONE_CARD_INIT,
    })
    try {
      if (payload?.id) {
        const cardsRef = collectionGroup(firestore, `scenes`)
        const q = query(cardsRef, where('_id', '==', payload.id))
        const querySnapshot = await getDocs(q)

        const res = querySnapshot.docs.map((doc) => ({
          _id: doc.id,
          ...doc.data(),
        }))

        dispatch({
          type: Action.GET_ONE_CARD_SUCCESS,
          payload: res ? res[0] : {},
        })
        resolve(res ? res[0] : {})
      } else {
        const sessid = short.generate()
        payload['_id'] = sessid
        dispatch({
          type: Action.GET_ONE_CARD_SUCCESS,
          payload: payload,
        })
        resolve(payload)
      }
    } catch (error: any) {
      AppLog(error)
      dispatch({
        type: Action.GET_ONE_CARD_FAIL,
        payload: 'Failed to get card!',
      })
      reject(error)
    }
  })
}

export const getMarker: any = (payload: any) => (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    // const token: any = localStorage.getItem("userId") //omitting due to eslint warning
    dispatch({
      type: Action.GET_ONE_MARKER_INIT,
    })
    try {
      if (payload?.id) {
        const cardsRef = collectionGroup(firestore, `markers`)
        const q = query(cardsRef, where('_id', '==', payload.id))
        const querySnapshot = await getDocs(q)

        const res = querySnapshot.docs.map((doc) => ({
          _id: doc.id,
          ...doc.data(),
        }))

        dispatch({
          type: Action.GET_ONE_MARKER_SUCCESS,
          payload: res ? res[0] : {},
        })
        resolve(res)
      } else {
        const sessid = short.generate()
        payload['_id'] = sessid
        dispatch({
          type: Action.GET_ONE_MARKER_SUCCESS,
          payload: payload,
        })
        resolve(payload)
      }
    } catch (error: any) {
      AppLog(error)
      dispatch({
        type: Action.GET_ONE_MARKER_FAIL,
        payload: 'Failed to get marker!',
      })
      reject(error)
    }
  })
}

export const updateAnalytics: any = (jsonData: any, id: string) => async () => {
  return new Promise(async (resolve, reject) => {
    const uid = localStorage.getItem("userId")
    const currentDate = getCurrentDate()
    try {
      const cardRef = doc(firestore, `users/${uid}/cards/${id}`)
      const analyticsCollectionRef = collection(cardRef, 'analytics');
      const analyticsDocRef = doc(analyticsCollectionRef, currentDate);
      const analyticsDataWithTimestamp = { ...jsonData };

      // Check if the document exists
      const docSnapshot = await getDoc(analyticsDocRef);
      const existingData = docSnapshot.exists() ? docSnapshot.data() : {};

      // Increment existing values in the JSON
      for (const key in analyticsDataWithTimestamp) {
        if (typeof analyticsDataWithTimestamp[key] === 'object') {
          for (const subKey in analyticsDataWithTimestamp[key]) {
            existingData[key] = {
              ...existingData[key],
              [subKey]: (existingData[key]?.[subKey] || 0) + analyticsDataWithTimestamp[key][subKey],
            };
          }
        } else {
          existingData[key] = (existingData[key] || 0) + analyticsDataWithTimestamp[key];
        }
      }
      existingData.date = Timestamp.fromDate(new Date())
      if (!docSnapshot.exists()) {
        await setDoc(analyticsDocRef, existingData);
      } else {
        await updateDoc(analyticsDocRef, existingData);
      }

      resolve(existingData);
    } catch (error) {
      console.error('Error updating document:', error);
      reject(error);
    }
  });
};

export const getCardAnalytics: any = (payload: any) => (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    dispatch({
      type: Action.GET_CARD_ANALYTICS_INIT,
    })

    try {
      if (payload.id) {
        const cardsRef = collectionGroup(firestore, 'scenes')
        const q = query(cardsRef, where('_id', '==', payload.id))
        const querySnapshot = await getDocs(q)

        if (querySnapshot.docs.length > 0) {
          const doc = querySnapshot.docs[0]
          const analyticsRef = collection(doc.ref, 'analytics')

          const startDate = Timestamp.fromDate(new Date(payload.startDate));
          const endDate = Timestamp.fromDate(new Date(payload.endDate));
          const analyticsQuery = query(analyticsRef, where('timestamp', '>=', startDate), where('timestamp', '<=', endDate));

          const filteredAnalyticsQuerySnapshot = await getDocs(analyticsQuery)

          const analyticsData: Record<string, any[]> = {}
          filteredAnalyticsQuerySnapshot.docs.forEach((analyticsDoc) => {
            const collectionName = analyticsDoc.id
            const data = analyticsDoc.data()
            delete data['analyticsId']
            analyticsData[collectionName] = [data]
          })

          const cardData = {
            _id: doc.id,
            // ...doc.data(),
            analytics: analyticsData,
          }

          dispatch({
            type: Action.GET_CARD_ANALYTICS_SUCCESS,
            payload: cardData,
          })

          resolve(cardData)
        } else {
          // Handle case when no document is found
          dispatch({
            type: Action.GET_CARD_ANALYTICS_FAIL,
            payload: 'Card not found!',
          })

          resolve(null)
        }
      } else {
        // Handle case when payload is falsy
        const sessid = short.generate()
        payload = { _id: sessid }

        dispatch({
          type: Action.GET_CARD_ANALYTICS_SUCCESS,
          payload: payload,
        })

        resolve(payload)
      }
    } catch (error: any) {
      console.error(error)
      dispatch({
        type: Action.GET_CARD_ANALYTICS_FAIL,
        payload: 'Failed to get card analytics!',
      })

      resolve(null)
    }
  })
}

export const getTodayAnalytics: any = (payload: any) => (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    dispatch({
      type: Action.GET_TODAY_ANALYTICS_INIT,
    })

    try {
          const uid = localStorage.getItem('userId')
          const analyticsRef = collection(firestore,`users/${uid}/analytics`)

          const analyticsQuery = query(analyticsRef, where(payload.type, '==', payload.id), where('timestamp', '>=', payload.startDate));
          const querySnapshot = await getDocs(analyticsQuery);

          let res = {}
          querySnapshot.docs.forEach((doc:any) => {
            let docd = doc.data()
            if (docd?.ip in res){
              res[docd?.ip].push(docd)
            }else{
              res[docd?.ip] = [docd]
            }
          })

          dispatch({
            type: Action.GET_TODAY_ANALYTICS_SUCCESS,
            payload: res,
          })

          resolve(res)

    } catch (error: any) {
      console.error(error)
      dispatch({
        type: Action.GET_TODAY_ANALYTICS_FAIL,
        payload: 'Failed to get card analytics!',
      })

      resolve(null)
    }
  })
}

export const deleteCard: any = (payload: any) => (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    const uid = localStorage.getItem('userId')
    dispatch({
      type: Action.CARD_DELETE_INIT,
    })
    try {
      const cardRef = doc(firestore, `users/${uid}/scenes/${payload}`)
      const ref = await updateDoc(cardRef, { isDeleted: true })
      dispatch({
        type: Action.CARD_DELETE_SUCCESS,
        payload: ref,
      })
      resolve(ref)
    } catch (error: any) {
      dispatch({
        type: Action.CARD_DELETE_FAIL,
        payload: 'Failed to delete card!',
      })
    }
  })
}

export const deleteMarker: any = (payload: any) => (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    const uid = localStorage.getItem('userId')
    dispatch({
      type: Action.MARKER_DELETE_INIT,
    })
    try {
      const cardRef = doc(firestore, `users/${uid}/markers/${payload}`)
      const ref = await updateDoc(cardRef, { isDeleted: true })
      dispatch({
        type: Action.MARKER_DELETE_SUCCESS,
        payload: ref,
      })
      resolve(ref)
    } catch (error: any) {
      dispatch({
        type: Action.MARKER_DELETE_FAIL,
        payload: 'Failed to delete marker!',
      })
    }
  })
}

export const restoreCard: any = (payload: any) => (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    const uid = localStorage.getItem('userId')
    dispatch({
      type: Action.CARD_RESTORE_INIT,
    })
    try {
      const cardRef = doc(firestore, `users/${uid}/scenes/${payload}`)
      const ref = await updateDoc(cardRef, { isDeleted: false })
      dispatch({
        type: Action.CARD_RESTORE_SUCCESS,
        payload: ref,
      })
      resolve(ref)
    } catch (error: any) {
      dispatch({
        type: Action.CARD_RESTORE_FAIL,
        payload: 'Failed to restore card!',
      })
    }
  })
}

export const getActiveCards: any = (payload: any) => (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    const uid = localStorage.getItem('userId')
    dispatch({
      type: Action.GET_ACTIVE_CARD_INIT,
    })
    try {
      let plimit = payload?.itemsPerPage ? payload?.itemsPerPage : 100
      let page = payload?.currentPage ? payload?.currentPage : 1
      let offset = (page - 1) * plimit

      AppLog(plimit)
      AppLog(page)
      AppLog(offset)
      AppLog(offset + plimit)
      const cardsRef = collection(firestore, `users/${uid}/scenes`)
      const q = query(cardsRef, where('protected.isActive', '==', true), where('isDeleted', '==', false))
      const querySnapshot = await getDocs(q)

      const res = querySnapshot.docs.map((doc) => {
        return doc.data()
      })
      dispatch({
        type: Action.GET_ACTIVE_CARD_SUCCESS,
        payload: res,
      })
      resolve(res)
    } catch (error: any) {
      AppLog(error)
      dispatch({
        type: Action.GET_ACTIVE_CARD_FAIL,
        payload: 'Failed to get scenes!',
      })
    }
  })
}

function getCurrentDate() {
  const now = new Date();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const year = now.getFullYear();
  return `${month}-${day}-${year}`;
}