/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { useAuth } from '../core/Auth'
import { useDispatch } from 'react-redux'
import { authLogin } from '../../../../redux/action/auth'
import { Button } from 'react-bootstrap'
import { getCards } from '../../../../redux/action/card'
// import { getUserByToken, login } from '../core/_requests'
// import { toAbsoluteUrl } from '../../../../_4threal/helpers'
// import { generatePopup } from '../../../../utils/popup' //omitted due to eslint warning
// import { useSelector } from 'react-redux' //omitted due to eslint warning

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Wrong email format').required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const [passwordVisible, setPasswordVisible] = useState(false)
  // const { saveAuth, setCurrentUser } = useAuth() //omitted due to eslint warning
  const { saveAuth } = useAuth()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const bgTransparent = { background: 'transparent' }

  const viewicon = {
    top: '50% ',
    transform: 'translateY(-50%)',
    right: '10px',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        //await signInWithEmailAndPassword(auth, values.email, values.password);

        dispatch(
          authLogin(
            {
              email: values.email,
              password: values.password,
            },
          )
        )
          .then((res) => {
            dispatch(getCards(50, 1, res.uid)).then((resp) => {
              /*
              if (resp?.length > 0) {
                navigate("/dashboard")
              } else {
                navigate("/firstbc/full")
              }
              */
              navigate("/dashboard")
            })
            // navigate("/")
            // navigate
            localStorage.setItem('userId', res.uid)
            // Handle the response here
          })
          .catch((error) => {
            setStatus(error?.response?.data?.message)
            // Handle the error here
          })

        setLoading(false)
      } catch (error) {
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-start mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Hello</h1>
        <p className='font-dark1'>Sign in to your Xtynded Reality&#8482; account.</p>
      </div>
      {/* <div className='row g-3 mb-9'>
        <div className='col-md-6'>
        </div>
        <div className='col-md-6'>
        </div>
      </div> */}
      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <></>
      )}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6  font-dark1'>Email address</label>
        <input
          placeholder='Email'
          style={bgTransparent}
          {...formik.getFieldProps('email')}
          className={clsx(
            `form-control`,
            { 'border-red': formik.touched.email && formik.errors.email },
            {
              ' border-primary': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-5'>
        <div className='d-flex align-items-center justify-content-between mb-2'>
          <label className='form-label  font-dark1 fs-6 mb-0'>Password</label>
          <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold '>
            <div />
            <Link to='/auth/forgot-password' style={{ color: '#767676' }}>
              Forgot Password ?
            </Link>
          </div>
        </div>
        <div className='position-relative'>
          <input
            placeholder='password'
            type={passwordVisible ? 'text' : 'password'}
            style={bgTransparent}
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent ',
              {
                'border-red text-red': formik.touched.password && formik.errors.password,
              },
              {
                'border-primary': formik.touched.password && !formik.errors.password,
              }
            )}
          />

          {passwordVisible ? (
            <i
              style={viewicon}
              onClick={() => setPasswordVisible(!passwordVisible)}
              className='bi bi-eye-slash fs-5 position-absolute'
            ></i>
          ) : (
            <i
              style={viewicon}
              onClick={() => setPasswordVisible(!passwordVisible)}
              className='bi bi-eye fs-5 position-absolute'
            ></i>
          )}
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input '
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />

          <span className='font-dark1'>Remember this account</span>
          {/* {formik.touched.acceptTerms && formik.errors.acceptTerms && ( */}
          {/* <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{formik.errors.acceptTerms}</span>
          </div>
      </div> */}
          {/* )} */}
        </label>
      </div>

      <div className='row align-items-center'>
        <div className='col-12 col-md-6'>
          <Button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-primary  my-3 w-100'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Login</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </Button>
        </div>
        <div className='col-12 col-md-6'>
          <div className='font-dark1 text-center fs-6'>
            New to Xtynded Reality&#8482;?<br/>
            <Link to='/auth/registration' className='text-decoration-underline font-dark1  fw-semibold'>
              Sign Up For The Beta
            </Link>
          </div>
        </div>
      </div>
    </form>
  )
}
